import { addons } from '@storybook/manager-api'
import bricksTheme from './bricks-theme'

addons.setConfig({
  isFullscreen: false,
  showNav: true,
  showPanel: 'right',
  panelPosition: 'right',
  enableShortcuts: true,
  showToolbar: true,
  theme: bricksTheme,
  selectedPanel: undefined,
  initialActive: 'sidebar',
  sidebar: {
    showRoots: true,
    collapsedRoots: ['other']
  },
  toolbar: {
    title: { hidden: false },
    zoom: { hidden: false },
    eject: { hidden: false },
    copy: { hidden: false },
    fullscreen: { hidden: false }
  }
})
