import { create } from '@storybook/theming/create'

const bricksTheme = create({
  base: 'light',
  brandTitle: '@alds/bricks',
  brandUrl: '/',
  brandImage: './icon.svg',
  brandTarget: '_self',

  // UI
  appBg: '#ffffff'
})

export default bricksTheme
